
import { defineComponent } from 'vue'

import MbrApp from '@/views/mbr/app.vue'
import OpApp from '@/views/op/app.vue'
import SerpApp from '@/views/serp/app.vue'

import { portal } from '@/h'

export default defineComponent({
  setup() {
    let app
    switch (portal()) {
      case 'op':
        app = OpApp
        document.body.setAttribute('portal', 'op')
        break
      case 'serp':
        app = SerpApp
        document.body.setAttribute('portal', 'serp')
        break
      default:
        app = MbrApp
        document.body.setAttribute('portal', 'mbr')
    }
    return {
      app
    }
  }
})
