
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import { i18n } from '@/h'

export default defineComponent({
  props: {
    pt: {
      type: String,
      default: i18n.app.name
    },
    backTo: {
      type: String,
      default: '/ow'
    }
  },
  setup() {
    const currentMenu = ref('welcome')
    const route = useRoute()

    switch (route.path) {
      case '/ow/welcome/about':
        currentMenu.value = 'about'
        break;
      case '/ow/welcome/contact':
        currentMenu.value = 'contact'
        break;
      case '/ow/welcome/magazine':
      case '/ow/welcome/trial':
        currentMenu.value = 'magazine'
        break;
      default:
        if (route.path.startsWith('/ow/notices')) {
          currentMenu.value = 'notices'
        } else if (route.path.startsWith('/ow/articles')) {
          currentMenu.value = 'articles'
        } else if (route.path.startsWith('/ow/items')) {
          currentMenu.value = 'items'
        } else if (route.path.startsWith('/ow/orgs')) {
          currentMenu.value = 'orgs'
        } else {
          currentMenu.value = 'welcome'
        }
        break;
    }

    return {
      currentMenu
    }
  }
})
