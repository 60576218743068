
import { IonApp, IonRouterOutlet, IonSpinner } from '@ionic/vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    IonApp,
    IonRouterOutlet,
    IonSpinner
  }
})
