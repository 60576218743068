
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { portalName, axios, session } from '@/h'

export default defineComponent({
  props: {
    pt: {
      type: String,
      default: portalName()
    },
    backTo: {
      type: String,
      default: '/serp/home'
    }
  },
  setup() {
    const router = useRouter()
    const signOut = function () {
      axios.delete('/serp/visit/signins/0').then(() => {
        session.signOut()
        router.replace('/serp/welcome')
      })
    }
    return {
      signOut
    }
  }
})
