
import { defineComponent, ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { axios } from '@/h'

export default defineComponent({
  props: {
    url: {
      default: null
    },
    d: {
      default: {}
    },
    k: {
      default: ''
    },
    q: {
      default: {}
    }
  },
  setup(props) {
    const cnt = computed(() => (props.d as any)[props.k].length)
    const disabled = ref(false)
    const route = useRoute()
    const more = (ev?: CustomEvent) => {
      axios.get(props.url || route.path, {
        params: Object.assign(Object.assign({}, props.q), { offset: cnt.value })
      }).then((response) => {
        const newList = response.data[props.k]
        ;(props.d as any)[props.k].push(...newList)

        if (ev) (ev.target as any).complete()
        if (newList.length <= 0) disabled.value = true
      })
    }

    watch(props.d, () => disabled.value = cnt.value <= 0)

    return {
      cnt,
      disabled,
      more
    }
  }
})
