
import { defineComponent } from 'vue'
import { i18n } from '@/h'

export default defineComponent({
  props: {
    pt: {
      type: String,
      default: i18n.company.name
    },
    backTo: {
      type: String,
      default: '/ow'
    }
  },
  setup() {
    const friendLinks = {
      国务院有关部委: {
        工业和信息化部: 'http://www.miit.gov.cn',
        国家国防科技工业局: 'http://www.sastind.gov.cn'
      },
      军队网站: {
        全军武器装备采购信息网: 'http://www.weain.mil.cn',
        军队采购网: 'http://www.plap.cn'
      },
      各地军民结合主管部门网站: {
        北京市经济和信息化委员会: 'http://www.bjeit.gov.cn',
        天津市工业和信息化委员会: 'http://www.tjec.gov.cn',
        河北省工业和信息化厅: 'http://www.ii.gov.cn',
        内蒙古经济和信息化委员会: 'http://www.nmgjxw.gov.cn',
        辽宁省经济和信息化委员会: 'http://www.lneic.gov.cn',
        吉林省工业和信息化厅: 'http://gxt.jl.gov.cn',
        黑龙江省工业和信息化委员会: 'http://www.hljiic.gov.cn',
        上海市经济和信息化委员会: 'http://www.sheitc.gov.cn',
        江苏省经济和信息化委员会: 'http://www.jseic.gov.cn',
        浙江省经济和信息化委员会: 'http://www.zjjxw.gov.cn',
        安徽省经济和信息化委员会: 'http://www.aheic.gov.cn',
        福建省经济和信息化委员会: 'http://www.fjetc.gov.cn',
        江西省工业和信息化委员会: 'http://www.jxciit.gov.cn',
        山东省经济和信息化委员会: 'http://www.sdeic.gov.cn',
        河南省工业和信息化委员会: 'http://www.iitha.gov.cn',
        湖北省国防科学技术工业办公室: 'http://www.hbgb.gov.cn',
        湖南省经济和信息化委员会: 'http://www.hnjxw.gov.cn',
        广东省经济和信息化委员会: 'http://www.gdei.gov.cn',
        广西工业和信息化委员会: 'http://www.gxgxw.gov.cn',
        海南省工业和信息化厅: 'http://iitb.hainan.gov.cn',
        重庆市经济和信息化委员会: 'http://wjj.cq.gov.cn',
        四川省经济和信息化委员会: 'http://www.scjm.gov.cn',
        贵州省经济和信息化委员会: 'http://www.gzjxw.gov.cn',
        云南省工业和信息化委员会: 'http://www.ynetc.gov.cn',
        西藏工业和信息化厅: 'http://www.xzgxt.gov.cn',
        陕西省工业和信息化厅: 'http://www.sxgxt.gov.cn',
        青海省经济和信息化委员会: 'http://www.qhec.gov.cn',
        宁夏经济和信息化委员会: 'http://www.nxetc.gov.cn',
        新疆经济和信息化委员会: 'http://www.xjeic.gov.cn'
      },
      军工集团中物院: {
        中国核工业集团公司: 'http://www.cnnc.com.cn',
        中国核工业建设集团公司: 'http://www.cnecc.com',
        中国航天科技集团公司: 'http://www.spacechina.com',
        中国航天科工集团公司: 'http://www.casic.com.cn',
        中国航空工业集团公司: 'http://www.avic.com.cn',
        中国船舶工业集团公司: 'http://www.cssc.net.cn',
        中国船舶重工集团公司: 'http://www.csic.com.cn',
        中国兵器工业集团公司: 'http://www.norincogroup.com.cn',
        中国兵器装备集团公司: 'http://www.chinasouth.com.cn',
        中国电子信息产业集团公司: 'http://www.cec.com.cn',
        中国工程物理研究院: 'http://www.caep.ac.cn',
        航天云网: 'http://www.casicloud.com'
      },
      中小企业公共服务平台: {
        中小企业信息网: 'http://www.sme.gov.cn',
        西安科技大市场: 'http://www.xatrm.com',
        辽宁省中小企业公共服务平台: 'http://www.lnsme.gov.cn',
        吉林中小企业公共服务平台: 'http://fwpt.smejl.gov.cn',
        黑龙江中小企业公共服务平台: 'http://www.longfuwu.cn',
        上海市中小企业服务互动平台: 'http://www.ssme.gov.cn',
        浙江省中小企业公共服务平台: 'http://www.96871.com.cn',
        中国中小企业信息网安徽网: 'http://www.smeah.gov.cn',
        江西省中小企业公共服务平台: 'http://www.jx968969.cn',
        山东省中小企业公共服务平台: 'http://www.smesd.gov.cn',
        广东省中小企业公共服务平台: 'http://www.968115.cn',
        四川省中小企业公共服务平台: 'http://www.smesc.cn',
        北京市中小企业公共服务平台: 'http://www.smebj.cn',
        河北省中小企业公共服务平台: 'http://www.smehb.cn',
        山西省中小企业公共服务平台: 'http://www.sme8718.com',
        江苏省中小企业公共服务平台: 'http://www.smejs.cn',
        宁波市中小企业公共服务平台: 'http://www.87188718.com',
        青岛市中小企业云服务平台: 'http://www.smeqd.gov.cn',
        湖北省中小企业公共服务平台: 'http://www.hbsme.com.cn',
        湖南省中小企业公共服务平台: 'http://www.smehn.cn',
        重庆市中小企业局: 'http://www.cqsme.gov.cn',
        陕西省中小企业公共服务平台: 'http://www.sxsme.com',
        内蒙古中小企业公共服务平台: 'http://www.nmgsme.gov.cn',
        中国中小企业大连网: 'http://www.smedl.gov.cn',
        福建省中小企业公共服务平台: 'http://www.fujiansme.com',
        厦门中小在线: 'http://www.xmsme.gov.cn',
        河南省中小企业公共服务平台: 'http://www.smeha.cn',
        深圳市中小企业公共服务平台: 'http://www.smemall.cn',
        贵州省中小企业公共服务平台: 'http://www.smegz.com',
        云南省中小企业公共服务平台: 'http://www.ynsmes.cn',
        甘肃省中小企业公共服务平台: 'http://www.gs96871.com',
        中国中小企业青海网: 'http://www.smeqh.gov.cn',
        广西中小企业公共服务平台: 'http://www.gxsme.org.cn',
        海南省中小企业公共服务平台: 'http://www.0898sme.com',
        宁夏中小企业公共服务平台: 'http://www.smenx.gov.cn',
        新疆中小企业公共服务平台: 'http://www.smexj.gov.cn'
      },
      更多: {
        警用装备网: '',
        安防装备网: '',
        中国军事大百科: '',
        历史战争: '',
        现代战争: '',
        未来战争: '',
        现代科技: '',
        未来科技: '',
        历史士兵: '',
        现代士兵: '',
        未来士兵: ''
      }
    }
    const friendLinkPostfix = new Date().getTime()

    return {
      friendLinks, friendLinkPostfix
    }
  }
})
