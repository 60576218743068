
import { defineComponent, ref, toRef, watch } from 'vue'

export default defineComponent({
  props: {
    name: String,
    value: Number
  },
  setup(props) {
    const value = toRef(props, 'value'), fen = ref(null as null | number)
    watch(value, (newValue) => {
      if (newValue || newValue === 0) {
        fen.value = newValue / 100
      }
    })
    return {
      fen
    }
  }
})
