
import { defineComponent } from 'vue'
import { portalName } from '@/h'

export default defineComponent({
  props: {
    pt: {
      type: String,
      default: portalName()
    },
    backTo: {
      type: String,
      default: '/mbr'
    }
  }
})
