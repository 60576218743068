import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3368255e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "xx-large bolder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createBlock(_component_ion_header, { translucent: true }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_buttons, { slot: "start" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_thumbnail, {
                        class: "ion-margin-vertical",
                        "router-link": "/ow"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_img, { src: "/assets/icon/icon.png" })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.pt), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_buttons, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_searchbar, {
                        placeholder: _ctx.i18n.search,
                        class: "ion-hide-lg-down"
                      }, null, 8, ["placeholder"]),
                      _createVNode(_component_ion_button, {
                        color: "primary",
                        fill: "solid",
                        "router-link": "/"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.i18n.sign_in), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_toolbar, { color: "primary" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, {
            fixed: "",
            class: "ion-no-padding"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        expand: "block",
                        fill: "clear",
                        "router-link": "/ow/welcome",
                        "data-current-menu": _ctx.currentMenu === 'welcome'
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(_ctx.i18n.home), 1)
                        ]),
                        _: 1
                      }, 8, ["data-current-menu"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        expand: "block",
                        fill: "clear",
                        "router-link": "/ow/notices",
                        "data-current-menu": _ctx.currentMenu === 'notices'
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(_ctx.i18n.m('notice')), 1)
                        ]),
                        _: 1
                      }, 8, ["data-current-menu"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        expand: "block",
                        fill: "clear",
                        "router-link": "/ow/articles",
                        "data-current-menu": _ctx.currentMenu === 'articles'
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(_ctx.i18n.m('article')), 1)
                        ]),
                        _: 1
                      }, 8, ["data-current-menu"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        expand: "block",
                        fill: "clear",
                        "router-link": "/ow/items",
                        "data-current-menu": _ctx.currentMenu === 'items'
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(_ctx.i18n.mall), 1)
                        ]),
                        _: 1
                      }, 8, ["data-current-menu"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        expand: "block",
                        fill: "clear",
                        "router-link": "/ow/orgs",
                        "data-current-menu": _ctx.currentMenu === 'orgs'
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(_ctx.i18n.m('org')), 1)
                        ]),
                        _: 1
                      }, 8, ["data-current-menu"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        expand: "block",
                        fill: "clear",
                        "router-link": "/ow/welcome/magazine",
                        "data-current-menu": _ctx.currentMenu === 'magazine'
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(_ctx.i18n.magazine), 1)
                        ]),
                        _: 1
                      }, 8, ["data-current-menu"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        expand: "block",
                        fill: "clear",
                        "router-link": "/ow/welcome/about",
                        "data-current-menu": _ctx.currentMenu === 'about'
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(_ctx.i18n.about), 1)
                        ]),
                        _: 1
                      }, 8, ["data-current-menu"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        expand: "block",
                        fill: "clear",
                        "router-link": "/ow/welcome/contact",
                        "data-current-menu": _ctx.currentMenu === 'contact'
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(_ctx.i18n.contact), 1)
                        ]),
                        _: 1
                      }, 8, ["data-current-menu"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}